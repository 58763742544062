import React from 'react';
import _ from 'lodash';

import { Layout } from '../components/index';
import { markdownify, htmlToReact } from '../utils';

export default class Gallery extends React.Component {
    render() {
        const images = _.get(this.props, 'pageContext.frontmatter.images') || [];
        console.log(images);
        console.log(this.props);

        return (
            <Layout {...this.props}>
                <article id="main">
                    <header>
                        <h2>{_.get(this.props, 'pageContext.frontmatter.title')}</h2>
                        {markdownify(_.get(this.props, 'pageContext.frontmatter.subtitle'))}
                    </header>
                    <section className={'wrapper ' + _.get(this.props, 'pageContext.frontmatter.background_style')}>
                        <div className="inner">
                            {htmlToReact(_.get(this.props, 'pageContext.html'))}
                        </div>
                    </section>

                    <section className={'wrapper ' + _.get(this.props, 'pageContext.frontmatter.background_style')}>
                        <div className="inner">
                            <section>
                                <div className='box alt'>
                                    <div className='row gtr-50 gtr-uniform'>
                                        {
                                            images.map(i => (
                                                <div className="col-12-small col-6-medium col-3">
                                                    <div className='image fit'>
                                                        <a href={i.src}>
                                                            <img src={i.src} alt={i.alt} />
                                                        </a>
                                                    </div>
                                                    <div className="label fit">
                                                        <p className="center">{i.title}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </article>
            </Layout>
        );
    }
}
